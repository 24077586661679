/*!
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       

*/
/* Kendo RTL CSS */
.k-rtl {
  direction: rtl;
}
/* AutoComplete */
.k-rtl .k-autocomplete .k-loading,
.k-rtl .k-multiselect .k-loading {
  right: auto;
  left: 3px;
}
/* Buttons */
.k-rtl .k-button-icontext .k-icon,
.k-rtl .k-button-icontext .k-image {
  margin-right: -0.2rem;
  margin-left: .2rem;
}
/* TextBoxes, Pickers, Combos */
.k-rtl .k-dropdown-wrap,
.k-rtl .k-picker-wrap,
.k-rtl .k-numeric-wrap {
  padding-right: 0;
  padding-left: 1.9em;
}
.k-rtl .k-numeric-wrap.k-expand-padding {
  padding-left: 0;
}
.k-rtl .k-datetimepicker .k-picker-wrap {
  padding-right: 0;
  padding-left: 3.8em;
}
.k-rtl span.k-picker-wrap .k-select,
.k-rtl .k-numeric-wrap .k-select,
.k-rtl .k-dropdown-wrap .k-select {
  right: auto;
  left: 0;
  border-width: 0 1px 0 0;
}
.k-rtl .k-dropdown-wrap .k-input,
.k-rtl .k-picker-wrap .k-input,
.k-rtl .k-numeric-wrap .k-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.k-webkit .k-rtl .k-dropdown-wrap .k-input,
.k-webkit .k-rtl .k-numeric-wrap .k-input {
  margin-right: -1px;
}
.k-rtl .k-datetimepicker .k-picker-wrap .k-icon {
  margin: 0 2px 0 0;
}
.k-rtl.k-popup > .k-static-header {
  text-align: left;
  padding-left: 22px;
}
.k-rtl.k-popup .k-item > .k-group {
  right: auto;
  left: 0;
}
.k-rtl.k-popup .k-state-hover > .k-group {
  right: auto;
  left: -1px;
}
.k-rtl.k-popup > .k-group-header,
.k-rtl.k-popup > .k-virtual-wrap > .k-group-header {
  text-align: left;
}
/* Calendar */
.k-rtl .k-widget.k-calendar .k-nav-prev {
  left: auto;
  right: 1%;
}
.k-rtl .k-widget.k-calendar .k-nav-next {
  right: auto;
  left: 1%;
}
/* Editor */
.k-rtl .k-form-text-row input {
  margin-right: 0;
  margin-left: 1em;
}
.k-rtl .k-form-checkbox-row input {
  margin-left: 0;
  margin-right: 7.5em;
}
.k-rtl .k-form-checkbox-row label {
  padding-left: 0;
  padding-right: 0.3em;
}
.k-rtl .k-breadcrumbs {
  float: right;
  padding-right: 0;
  padding-left: 2px;
}
.k-rtl .k-search-wrap {
  float: left;
}
.k-rtl .k-search-wrap .k-search {
  right: auto;
  left: 2px;
}
.k-rtl .k-breadcrumbs-wrap {
  left: auto;
  right: 0;
  padding-left: 0;
  padding-right: 5px;
}
.k-rtl .k-editor-dialog .k-button-wrapper {
  text-align: left;
}
.k-rtl .k-tiles-arrange {
  float: left;
}
.k-rtl .k-toolbar-wrap,
.k-rtl .k-tile {
  float: right;
}
.k-rtl .k-imagebrowser .k-thumb {
  float: right;
  margin: 4px 4px 0 10px;
}
.k-rtl .k-imagebrowser .k-state-hover .k-i-arrow-e,
.k-rtl .k-imagebrowser .k-button:hover .k-i-arrow-e {
  background-position: -16px -48px;
}
.k-rtl .k-tile strong {
  float: right;
}
/* Grid */
.k-rtl div.k-grid-header,
.k-rtl div.k-grid-footer {
  padding-right: 0;
  padding-left: 17px;
}
.k-rtl .k-grid-header-wrap,
.k-rtl .k-grid-footer-wrap {
  border-width: 0;
}
.k-rtl .k-header > .k-grid-filter,
.k-rtl .k-header > .k-header-column-menu {
  float: left;
}
.k-rtl .k-grid-header .k-filterable .k-link {
  padding-left: 2.4em;
  padding-right: .6em;
}
.k-rtl .k-grid-header .k-header:first-child,
.k-rtl .k-filter-row th:first-child,
.k-rtl .k-grid tbody td:first-child,
.k-rtl .k-grid tfoot td:first-child {
  border-left-width: 1px;
}
.k-rtl div.k-grid td.k-hierarchy-cell {
  border-left-width: 0;
}
.k-rtl .k-grid .k-group-cell + td,
.k-rtl .k-grid .k-hierarchy-cell + td {
  border-left-width: 1px;
}
.k-rtl .k-grid-header .k-header {
  text-align: right;
}
.k-rtl .k-scrollbar-vertical {
  right: auto;
  left: 0;
}
.k-rtl .k-group-footer .k-group-cell + td {
  border-left-width: 0;
  border-right-width: 1px;
}
.k-rtl .k-grid-header-locked,
.k-rtl .k-grid-content-locked {
  border-width: 0;
}
.k-rtl .k-grid-header .k-with-icon .k-link {
  margin-right: -0.6em;
  margin-left: 1.3em;
}
.k-rtl .k-filtercell > span {
  padding-right: 0;
  padding-left: 4.8em;
}
.k-rtl .k-filtercell > .k-operator-hidden {
  padding-right: 0;
  padding-left: 2.3em;
}
.k-rtl .k-filtercell > span > .k-button {
  right: auto;
  left: 0;
}
.k-rtl .k-filter-row .k-dropdown-operator {
  right: auto;
  left: 2.5em;
}
/* Editing */
.k-rtl .k-edit-label,
.k-rtl .k-edit-form-container .editor-label {
  float: right;
  clear: both;
  margin-left: 0;
  margin-right: 2%;
  text-align: left;
}
.k-rtl .k-edit-field,
.k-rtl .k-edit-form-container .editor-field {
  float: left;
  clear: left;
  margin-right: 0;
  margin-left: 2%;
}
.k-rtl .k-edit-field > input[type="checkbox"]:first-child,
.k-rtl .k-edit-field > input[type="radio"]:first-child,
.k-rtl .k-edit-field > label:first-child > input[type="checkbox"],
.k-rtl .k-edit-field > .k-button:first-child {
  margin-right: 0;
}
.k-rtl .k-edit-form-container .k-edit-buttons {
  text-align: left;
}
.k-rtl .k-dirty {
  border-color: #f00 #f00 transparent transparent;
  margin: -0.45em -0.6em 0 0;
}
/* Pager */
.k-rtl .k-pager-info,
.k-rtl .k-pager-wrap .k-pager-refresh {
  float: left;
}
.k-rtl .k-pager-wrap > .k-link,
.k-rtl .k-pager-wrap .k-pager-numbers,
.k-rtl .k-pager-numbers li,
.k-rtl .k-pager-input {
  float: right;
}
/* Gantt*/
.k-rtl .k-gantt .k-grid-header .k-header {
  text-align: center;
}
.k-rtl .k-gantt-actions {
  float: right;
  margin-right: 0;
  margin-left: .6em;
}
.k-rtl .k-gantt-toolbar > .k-gantt-views {
  float: left;
  margin-left: 0;
}
.k-rtl .k-grid tbody td:last-child,
.k-rtl .k-grid thead th:last-child {
  border-left-width: 0;
}
.k-rtl .k-timeline .k-grid-header-wrap {
  border-left-width: 1px;
}
.k-rtl .k-gantt-toolbar li:last-child,
.k-rtl .k-gantt-toolbar li:last-child > .k-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}
.k-rtl .k-gantt-toolbar li:last-child {
  border-left-width: 1px;
}
.k-rtl .k-gantt-toolbar li:first-child {
  border-left-width: 0;
}
.k-rtl .k-gantt-toolbar li:first-child,
.k-rtl .k-gantt-toolbar li:first-child > .k-link {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.k-rtl .k-gantt-rows,
.k-rtl .k-gantt-columns,
.k-rtl .k-gantt-dependencies {
  left: auto;
  right: 0;
}
.k-rtl .k-task-complete {
  right: 0;
  left: auto;
}
.k-rtl .k-task-template {
  padding: .2em .6em .2em 1.4em;
}
.k-rtl .k-task-single {
  text-align: right;
}
.k-rtl .k-task-actions {
  right: auto;
  left: 4px;
}
.k-rtl .k-task-draghandle {
  margin-right: 16px;
}
.k-rtl .k-task-dot:after {
  margin-right: 4px;
  margin-left: 0;
}
.k-rtl .k-task-start {
  right: 0;
  left: auto;
}
.k-rtl .k-task-end {
  left: 0;
  right: auto;
}
.k-rtl .k-resources-wrap {
  margin-left: 0;
  margin-right: 20px;
}
/* Menu */
.k-rtl .k-menu .k-item,
.k-rtl .k-widget.k-menu-horizontal > .k-item {
  border-width: 0 0 0 1px;
}
.k-rtl .k-widget.k-menu-horizontal > .k-item {
  float: right;
}
.k-rtl .k-menu .k-item > .k-link > .k-i-arrow-s {
  margin-right: 0;
  margin-left: -8px;
}
.k-rtl .k-menu .k-item > .k-link > .k-i-arrow-e {
  right: auto;
  left: .2rem;
}
.k-rtl .k-menu .k-group .k-item > .k-link {
  padding-left: 1.8em;
  padding-right: .9em;
}
.k-rtl .k-menu .k-link > .k-i-arrow-e {
  background-position: 0 -48px;
}
.k-rtl .k-menu .k-link > .k-i-arrow-w {
  background-position: 0 -16px;
}
.k-rtl .k-menu .k-image,
.k-rtl .k-menu .k-sprite {
  margin-left: 4px;
  margin-right: -4px;
}
/* Multiselect */
.k-rtl .k-multiselect-wrap .k-input {
  float: right;
}
.k-rtl .k-multiselect-wrap li {
  float: right;
  padding: .1em .4em .1em 1.6em;
  margin: 2px 2px 2px 0;
}
.k-rtl .k-multiselect-wrap .k-select {
  right: auto;
  left: 0;
  padding: .1em .2em;
}
/* Notification */
.k-rtl .k-notification-button .k-notification-wrap,
.k-rtl.k-notification-button .k-notification-wrap {
  padding-right: .5em;
  padding-left: 20px;
}
.k-rtl .k-notification-wrap > .k-i-note {
  margin-right: 0;
  margin-left: 4px;
}
.k-rtl .k-notification-wrap > .k-i-close {
  right: auto;
  left: 4px;
}
/* PanelBar */
.k-rtl .k-panelbar-expand,
.k-rtl .k-panelbar-collapse {
  right: auto;
  left: 2px;
}
.k-rtl .k-panelbar .k-image,
.k-rtl .k-panelbar .k-sprite {
  float: right;
  margin-right: 0;
  margin-left: 5px;
}
/* Progressbar */
.k-rtl .k-progressbar > .k-reset {
  left: auto;
  right: -1px;
}
.k-rtl .k-progressbar-horizontal li.k-last {
  border-left-width: 0;
}
/* Scheduler */
.k-rtl .k-scheduler-toolbar > ul {
  float: left;
}
.k-rtl .k-scheduler-toolbar > ul:first-child {
  float: right;
}
.k-rtl .k-scheduler-toolbar > ul > li,
.k-rtl .k-scheduler-footer > ul > li {
  border-width: 1px 0 1px 1px;
}
.k-rtl .k-scheduler-toolbar > ul > li:first-child {
  border-right-width: 1px;
}
.k-rtl .k-scheduler div.k-scheduler-footer ul li {
  margin-right: 0;
  margin-left: .6em;
}
.k-rtl .k-scheduler-toolbar .k-i-calendar,
.k-rtl .k-scheduler-footer .k-icon {
  margin-right: 0;
  margin-left: 6px;
}
.k-rtl .k-scheduler .k-scrollbar-v .k-scheduler-header-wrap {
  border-right-width: 0;
  border-left-width: 1px;
}
.k-rtl .k-scheduler-monthview .k-scheduler-table td {
  text-align: left;
}
.k-rtl .k-scheduler-table td,
.k-rtl .k-scheduler-header th {
  border-left-width: 0;
  border-right-width: 1px;
}
.k-rtl .k-scheduler-table td:first-child,
.k-rtl .k-scheduler-header th:first-child {
  border-right-width: 0;
}
.k-rtl .k-scheduler-agendaview .k-scheduler-table td:first-child {
  border-left-width: 0;
  border-right-width: 1px;
}
.k-rtl .k-scheduler-agendaview .k-scheduler-table td.k-scheduler-datecolumn {
  border-right-width: 0;
}
.k-rtl .k-scheduler-times th {
  text-align: left;
  padding-right: .5em;
  padding-left: .6em;
  border-right-width: 0;
  border-left-width: 1px;
}
.k-rtl .k-scheduler-now-arrow {
  left: auto;
  right: 0;
}
.k-rtl .k-scheduler-now-line {
  left: 0;
  right: 5px;
}
.k-rtl .k-event,
.k-rtl .k-more-events {
  text-align: right;
}
.k-rtl .k-event-template {
  padding-left: 1.4em;
  padding-right: .6em;
}
.k-rtl .k-event-actions,
.k-rtl .k-event > .k-link,
.k-rtl .k-task > .k-link {
  right: auto;
  left: 4px;
}
.k-rtl .k-scheduler-agendaview .k-task > .k-link {
  right: auto;
  left: 0;
}
.k-rtl .k-event-actions:first-child {
  float: right;
  margin-left: 2px;
  margin-right: 4px;
}
.k-rtl .k-event .k-event-top-actions,
.k-rtl .k-event .k-event-bottom-actions {
  left: auto;
  right: 0;
}
.k-rtl .k-event > .k-resize-e {
  right: auto;
  left: 0;
}
.k-rtl .k-event > .k-resize-w {
  left: auto;
  right: 0;
}
.k-rtl .k-event > .k-resize-e:after,
.k-rtl .k-event > .k-resize-w:after {
  left: auto;
  right: 1px;
}
.k-rtl .k-event > .k-resize-e:after {
  right: auto;
  left: 1px;
}
.k-rtl .k-scheduler-marquee .k-label-top {
  left: auto;
  right: .8em;
}
.k-rtl .k-scheduler-marquee .k-label-bottom {
  right: auto;
  left: .81em;
}
.k-rtl .k-tooltip-bottom {
  text-align: right;
}
.k-rtl .k-tooltip-bottom .k-button {
  float: right;
  margin-right: 0;
  margin-left: .3em;
}
.k-rtl .k-tooltip-bottom .k-quickedit-details {
  float: left;
  margin-left: 0;
}
.k-rtl .k-scheduler-agendaview .k-scheduler-table th,
.k-rtl .k-scheduler-agendaview .k-scheduler-table td {
  text-align: right;
}
.k-rtl .k-scheduler-timecolumn > div > .k-i-arrow-e {
  right: auto;
  left: -4px;
}
.k-rtl .k-scheduler-timecolumn .k-i-arrow-w {
  margin-left: 0;
  margin-right: -4px;
}
.k-rtl .k-scheduler-mark {
  margin-right: 0;
  margin-left: .5em;
}
.k-rtl .k-scheduler-agendaday {
  float: right;
  margin: 0 0 0 .2em;
}
.k-rtl .k-scheduler-edit-form > .k-edit-box:first-child .k-datetimepicker {
  margin-right: 0;
  margin-left: 1em;
}
.k-rtl .k-edit-box {
  float: right;
}
.k-rtl .k-edit-box + .k-edit-box {
  float: left;
}
.k-rtl .k-scheduler-edit-form label + input {
  margin-left: 0;
  margin-right: 1em;
}
.k-rtl .k-edit-buttons .k-scheduler-delete {
  left: auto;
  right: .6em;
}
/* Slider */
.k-rtl .k-slider .k-button-decrease {
  left: auto;
  right: 0;
}
.k-rtl .k-slider .k-button-increase {
  right: auto;
  left: 0;
}
.k-rtl .k-slider-horizontal .k-slider-track,
.k-rtl .k-slider-horizontal .k-slider-selection {
  left: auto;
  right: 0;
}
.k-rtl .k-slider-horizontal .k-slider-buttons .k-slider-track {
  left: auto;
  right: 34px;
}
.k-rtl .k-slider .k-i-arrow-w {
  background-position: 0 -16px;
}
.k-rtl .k-slider .k-state-hover .k-i-arrow-w,
.k-rtl .k-slider .k-button:hover .k-i-arrow-w,
.k-rtl .k-slider .k-textbox:hover .k-i-arrow-w,
.k-rtl .k-slider .k-button:active .k-i-arrow-w {
  background-position: -16px -16px;
}
.k-rtl .k-slider .k-i-arrow-e {
  background-position: 0 -48px;
}
.k-rtl .k-slider .k-state-hover .k-i-arrow-e,
.k-rtl .k-slider .k-button:hover .k-i-arrow-e,
.k-rtl .k-slider .k-button:active .k-i-arrow-e,
.k-rtl .k-slider .k-button:active .k-i-arrow-e {
  background-position: -16px -48px;
}
/* TabStrip */
.k-rtl .k-tabstrip-items .k-item,
.k-rtl .k-panelbar .k-tabstrip-items .k-item {
  margin-left: -1px;
  margin-right: 0;
}
.k-rtl .k-tabstrip-items .k-item .k-image,
.k-rtl .k-tabstrip-items .k-item .k-sprite,
.k-rtl .k-panelbar .k-tabstrip-items .k-item .k-image,
.k-rtl .k-panelbar .k-tabstrip-items .k-item .k-sprite {
  margin: -3px -6px 0 3px;
}
.k-rtl .k-tabstrip-prev {
  left: auto;
  right: .4em;
}
.k-rtl .k-tabstrip-next {
  right: auto;
  left: .4em;
}
/* ToolBar */
.k-rtl .k-overflow-anchor {
  float: left;
  border-width: 0 1px 0 0;
}
.k-rtl .k-toolbar .k-button-group .k-button,
.k-rtl .k-toolbar .k-split-button .k-split-button-arrow {
  margin: 0 -1px 0 0;
}
.k-rtl.k-overflow-container .k-overflow-button,
.k-rtl.k-split-container .k-button {
  text-align: right;
}
/* ToolTip */
.k-rtl .k-tooltip-button {
  text-align: left;
}
.k-rtl .k-tooltip-closable .k-tooltip-content {
  padding-right: 0;
  padding-left: 20px;
}
/* TreeView */
.k-rtl .k-treeview .k-item {
  padding: 0 16px 0 0;
}
.k-rtl .k-treeview .k-plus,
.k-rtl .k-treeview .k-minus,
.k-rtl .k-treeview .k-plus-disabled,
.k-rtl .k-treeview .k-minus-disabled {
  margin-left: 0;
  margin-right: -16px;
}
.k-rtl .k-treeview .k-sprite,
.k-rtl .k-treeview .k-image {
  margin-right: 0;
  margin-left: 3px;
}
/* Upload */
.k-rtl .k-dropzone em {
  margin-left: 0;
  margin-right: .6em;
}
.k-rtl .k-upload-files .k-button,
.k-rtl .k-upload-status-total .k-icon {
  margin-left: 0;
  margin-right: 8px;
}
.k-rtl .k-file {
  padding-right: .8em;
  padding-left: .167em;
}
.k-rtl .k-filename {
  margin-left: 0;
  margin-right: 1em;
}
.k-rtl .k-upload-status {
  right: auto;
  left: 12px;
}
.k-rtl .k-progress {
  left: auto;
  right: 0;
}
.k-rtl .k-upload-selected {
  margin-right: .8em;
  margin-left: .2em;
}
/* Window */
.k-rtl .k-window-titlebar .k-window-actions {
  right: auto;
  left: .5em;
}
